<template>
  <div class="column q-gutter-md">
    <q-input v-model.number="c.timeout" type="number" @update:model-value="$emit('update:course', c)" filled dense
      label="Timeout" />
    <div class="column">
      <course-editor-node v-if="c.content" :label="c.content.type" @delete="deleteElement()"
        @copy="$clipboard.set(c.content)" @cut="$clipboard.set(c.content); deleteElement()">
        <component :is="c.content.type + 'Editor'" :course="c.content" @update:course="updateElement($event)" />
      </course-editor-node>
      <add-course-btn v-else @add="updateElement($event)" />
    </div>
  </div>
</template>

<script>
import editors from '@/components/Editor';
import AddCourseBtn from '@/components/utils/AddCourseBtn';
import CourseEditorNode from '../utils/CourseEditorNode.vue';

export default {
  components: {
    ...editors,
    AddCourseBtn,
    CourseEditorNode,
  },
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
  methods: {
    updateElement(e) {
      this.c.content = e;
      this.$emit('update:course', { ...this.c });
    },
    deleteElement() {
      this.c.content = null;
      this.$emit('update:course', { ...this.c });
    },
  },
};
</script>
