<template>
  <div class="column q-gutter-md">
    <!-- configurations of the list -->
    <q-select v-model="c.oltype" @update:model-value="$emit('update:course', c)" dense label="Type de liste"
      :options="[null, '1', 'A', 'a', 'I', 'i']" />
    <q-select v-model="c.title_tag" @update:model-value="$emit('update:course', c)" dense label="Type de titre"
      :options="['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']" />
    <q-toggle v-model="c.avoid_break" @update:model-value="$emit('update:course', c)" dense
      label="Eviter de couper en fin de page" />
    <!-- insert a new first element -->
    <add-course-btn @add="insertElement(0, $event)" />
    <!-- display sub courses -->
    <template v-for="[i, e] of c.elements.entries()" :key="e">
      <q-input v-model="e.title" dense label="Titre" />
      <course-editor-node :label="e.content.type" @delete="deleteElement(i)" @copy="$clipboard.set(e.content)"
        @cut="$clipboard.set(e.content); deleteElement(i)">
        <component :is="e.content.type + 'Editor'" :course="e.content" @update:course="updateElement(i, $event)" />
      </course-editor-node>
      <add-course-btn @add="insertElement(i + 1, $event)" />
    </template>
  </div>
</template>

<script>
import editors from '@/components/Editor';
import AddCourseBtn from '@/components/utils/AddCourseBtn';
import CourseEditorNode from '../utils/CourseEditorNode.vue';

export default {
  components: {
    ...editors,
    AddCourseBtn,
    CourseEditorNode,
  },
  props: ["course"],
  emits: ["update:course"],
  data() {
    return {
      c: this.course,
    };
  },
  created() {
    this.c.elements = this.c.elements || [];
  },
  methods: {
    updateElement(i, e) {
      this.c.elements[i].content = e;
      this.$emit('update:course', this.c);
    },
    insertElement(i, course) {
      this.c.elements.splice(i, 0, { title: '', content: course });
      this.$emit('update:course', { ...this.c });
    },
    deleteElement(i) {
      this.c.elements.splice(i, 1);
      this.$emit('update:course', { ...this.c });
    },
  },
};
</script>
